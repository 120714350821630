<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("activated_vehicles") }}</h1>
      <br>
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="vehicles">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
         <!--  <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Ajouter</span>
          </div> -->
      
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ vehicles.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : vehicles.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Nom</vs-th>
        <vs-th sort-key="type">Type</vs-th>
        <vs-th sort-key="plateNumber">N° de plaque</vs-th>
        <vs-th sort-key="greyCard">Carte grise</vs-th>
        <vs-th sort-key="model">Model</vs-th>
       <!--  <vs-th sort-key="frontAirbag">Dernière visite technique</vs-th>
        <vs-th sort-key="greyCard">Date d'expiration assurance</vs-th> -->
        <vs-th sort-key="greyCard">{{ $t("nokiaValidation") }}</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.name}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{voiture_Object[tr.type]}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.plateNumber}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.greyCard}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.model}}</p>
          </vs-td>
         <!--  <vs-td>
            <p class="product-name font-medium truncate">{{tr.lastTechnicalVisitDate}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.expiryDate}}</p>
          </vs-td> -->
          <vs-td>
            <vs-chip :color="getOrderStatusColor(tr.activated)" class="product-order-status">{{validationObject[tr.activated]}}</vs-chip>
          </vs-td>
         
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vx-tooltip  text="Image(s)" color="#28C76F">
                <feather-icon style="color: #14078f " icon="ImageIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"   @click="openLightbox" @click.stop="Show_image(tr)" color="warring" type="border" class="mr-2" />
              </vx-tooltip>
              <vx-tooltip  text="Voir" color="#28C76F">
                <feather-icon style="color: #06ac0e " icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success stroke-current"   @click.stop="ShowData(tr)" color="warring" type="border" class="mr-2" />
              </vx-tooltip>
              <vx-tooltip text="Modifier" color="warning">
                <feather-icon style="color: #FF9F43" icon="EditIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current" class="mr-2" @click="editData(tr)"/>
              </vx-tooltip>
              <vx-tooltip text="Supprimer" color="danger">
                <feather-icon @click="deleteData(tr.id)" style="color: red" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
              </vx-tooltip>
              <vx-tooltip text="Valier le véhicule" color="success">
                <feather-icon @click="comfirmation(tr.id)" style="color: #06ac0e" icon="CheckCircleIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="mr-2"/>
              </vx-tooltip>
              <vx-tooltip text="Rejeter le véhicule" color="danger">
                <feather-icon @click="rejet_Pop(tr.id)" style="color: #ff6141" icon="XCircleIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
              </vx-tooltip>
              <vx-tooltip text="Rapport" color="dark">
                <feather-icon v-if="tr.activated === 0 " @click="rapport(tr.vehicleComments)" style="color: black" icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-dark stroke-current" class="mr-2"/>
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
        <vs-popup title="Images du vehicle" :active.sync="showLightbox" class="items-no-padding">
        <div id="my-strictly-unique-vue-image-lightbox-carousel" style="text-align: center;">
          <vue-image-lightbox-carousel
            ref="lightbox"
            :show="showLightbox"
            @close="showLightbox = false"
            :images="images"
            @change="changeImage"
          >
          </vue-image-lightbox-carousel>
        </div>
        </vs-popup>
      </template>
    </vs-table>
    <vs-popup
    v-if="activePrompt2" 
      fullscreen 
      class="calendar-event-dialog"
      title="Ajouter un véhicule"
      :active.sync="activePrompt2">
      <div class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>Nom du véhicule<b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="name"
            v-model="name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
  
           <p> Type <b style="color: #ff6141" >*</b> </p>
              <vs-select
                v-model="type"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises" /></vs-select>
          
          
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first("type") }}</span>
           
            <p>N° de plaque<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="plateNumber"
              v-model="plateNumber"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('plateNumber')">{{ errors.first("plateNumber") }}</span>
            
            <p>Carte grise<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="greyCard"
              v-model="greyCard"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('greyCard')">{{ errors.first("greyCard") }}</span>
  
          
            <p>Nombre de ceinture de sécurité 02<b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="numberOf02PointsSeatBelt"
            v-model="numberOf02PointsSeatBelt"
            class="w-full"/>
            
            <span class="text-danger text-sm" v-show="errors.has('numberOf02PointsSeatBelt')">{{ errors.first("numberOf02PointsSeatBelt") }}</span>
            
            <p>Nombre de ceinture de sécurité 03<b style="color: #ff6141">*</b></p>

            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
              data-vv-validate-on="blur"
              name="numberOf03PointsSeatBelt"
              v-model="numberOf03PointsSeatBelt"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('numberOf03PointsSeatBelt')">{{ errors.first("numberOf03PointsSeatBelt") }}</span>
            <p> Air bague <b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="frontAirbag"
            autocomplete
            name="frontAirbag"
            class="w-full">
            </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('frontAirbag')">{{ errors.first("frontAirbag") }}</span>
          </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

            <p>Date de mise en circulation <b style="color: #ff6141">*</b></p>
            <flat-pickr 
              :config="configdateTimePicker" 
              label-placeholder=" Date"
              v-model="firtsReleaseDate"
              v-validate="'required'"
              name="firtsReleaseDate"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('firtsReleaseDate')">{{ errors.first("firtsReleaseDate") }}</span>
    
            <p> Compagnie d'assurance <b style="color: #ff6141" >*</b> </p>
              <v-select
                v-validate="'required'"
                name="insuranceCompany"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="insuranceCompany"
                :options="insuransces">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('insuranceCompany')">{{ errors.first("insuranceCompany") }}</span>
            
            <p>Type d'assurance<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="typeOfInsuranceContract"
              v-model="typeOfInsuranceContract"
              class="w-full"
              
            />
            <span class="text-danger text-sm" v-show="errors.has('typeOfInsuranceContract')">{{ errors.first("typeOfInsuranceContract") }}</span>
            
            
            <p>Numéro d'assurance <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="insurancePolicyNumber"
            v-model="insurancePolicyNumber"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('insurancePolicyNumber')">{{ errors.first("insurancePolicyNumber") }}</span>
            
            
            <p>Date d'expiration <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="expiryDate"
            v-validate="'required'"
            name="expiryDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('expiryDate')">{{ errors.first("expiryDate") }}</span>
            
            <p>Dernière visite technique<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="lastTechnicalVisitDate"
            v-validate="'required'"
            name="lastTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('lastTechnicalVisitDate')">{{ errors.first("lastTechnicalVisitDate") }}</span>
           
            <p>Date de visite technique à venir <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="nextTechnicalVisitDate"
            v-validate="'required'"
            name="nextTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('nextTechnicalVisitDate')">{{ errors.first("nextTechnicalVisitDate") }}</span>
  
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            
              
            <p>Date de maitenance à venir <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="dateOfTheNextMaintenanceVisit"
            v-validate="'required'"
            name="dateOfTheNextMaintenanceVisit"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('dateOfTheNextMaintenanceVisit')">{{ errors.first("dateOfTheNextMaintenanceVisit") }}</span>
  
             <p> Suivi ivms <b style="color: #ff6141" >*</b> </p>
             <vs-checkbox 
                v-model="ivmsTracker"
                data-vv-validate-on="blur"
                autocomplete
                name="ivmsTracker"
                class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('ivmsTracker')">{{ errors.first("ivmsTracker") }}</span>
    
            <p>Modèle <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="model"
            v-model="model"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('model')">{{ errors.first("model") }}</span>
         
            <p>Kilomètrage <b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="killometerOnTheDashboard"
            v-model="killometerOnTheDashboard"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('killometerOnTheDashboard')">{{ errors.first("killometerOnTheDashboard") }}</span>
         
            <p>Rapport ivms <b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
                v-model="ivmsReport"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="ivmsReport"
                class="w-full">
            </vs-checkbox>
             
          <span class="text-danger text-sm" v-show="errors.has('ivmsReport')">{{ errors.first("ivmsReport") }}</span>
            
          <p>Carnet d'entretien <b style="color: #ff6141">*</b></p>
          <vs-checkbox 
                v-model="maintenanceNotebook"
                autocomplete
                name="maintenanceNotebook"
                class="w-full">
            </vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('maintenanceNotebook')">{{ errors.first("maintenanceNotebook") }}</span>
           
            <p class="pt-4">Image(s)<b style="color: #ff6141" ></b> </p>
          <vs-button color="success" icon-pack="feather" icon="icon-image" @click="Vehicule_Image=true">Choisir</vs-button>
          <vs-popup title="Image(s)" :active.sync="Vehicule_Image" class="items-no-padding">
            <input type="file" class="hidden" ref="updateImgInput1" @change="updateCurrImg1" multiple accept="image/*">
            <input type="file" class="hidden" ref="updateImgInput2" @change="updateCurrImg2" multiple accept="image/*">
            <div class="carousel-example">
              <swiper  :options="swiperOption" style="height: 300px !important;">
                <swiper-slide  v-for="(data, index) in dataImg1" :key="index + '458'">
                  <vs-button
                    icon-pack="feather" icon="icon-trash" size="small" color="danger" @click="Delete_Image({id: data.id,key:index})">Supprimer
                  </vs-button>
                  <br>
                  <img  style="height: 300px !important;" class="responsive" :src= " data.url != null ? $store.state.image_url+data.url : data" alt="banner">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <br>
            <div v-if="this.update_Image === true " class="flex flex-wrap justify-between mb-3">
            <vs-button  icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput2.click()">Ajouter</vs-button>
            <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">Valider</vs-button>
            </div>
            <div v-if="this.update_Image === false " class="flex flex-wrap justify-between mb-3">
              <vs-button icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput1.click()">Ajout Nouvel</vs-button>
              <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">Valider</vs-button>
            </div>
          </vs-popup>
          
            
        </div>
      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Envoyer</vs-button>
     </div>
    </vs-popup>
    
    <vs-popup
    v-if="activePrompt1" 
      fullscreen 
      class="calendar-event-dialog"
      title="Modier un véhicule"
      :active.sync="activePrompt1">
      <div class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>Nom du véhicule<b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="name"
            v-model="name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
  
           <p> Type <b style="color: #ff6141" >*</b> </p>
              <vs-select
                v-model="type"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises" /></vs-select>
          
          
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first("type") }}</span>
           
            <p>N° de plaque<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="plateNumber"
              v-model="plateNumber"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('plateNumber')">{{ errors.first("plateNumber") }}</span>
            
            <p>Carte grise<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="greyCard"
              v-model="greyCard"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('greyCard')">{{ errors.first("greyCard") }}</span>
  
          
            <p>Nombre de ceinture de sécurité 02<b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="numberOf02PointsSeatBelt"
            v-model="numberOf02PointsSeatBelt"
            class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('numberOf02PointsSeatBelt')">{{ errors.first("numberOf02PointsSeatBelt") }}</span>
            
            <p>Nombre de ceinture de sécurité 03<b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            v-validate="'required'"
              data-vv-validate-on="blur"
              name="numberOf03PointsSeatBelt"
              v-model="numberOf03PointsSeatBelt"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('numberOf03PointsSeatBelt')">{{ errors.first("numberOf03PointsSeatBelt") }}</span>
            <p> Air bague <b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="frontAirbag"
            autocomplete
            name="frontAirbag"
            class="w-full">
            </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('frontAirbag')">{{ errors.first("frontAirbag") }}</span>
          </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

            <p>Date de mise en circulation <b style="color: #ff6141">*</b></p>
            <flat-pickr 
              :config="configdateTimePicker" 
              label-placeholder=" Date"
              v-model="firtsReleaseDate"
              v-validate="'required'"
              name="firtsReleaseDate"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('firtsReleaseDate')">{{ errors.first("firtsReleaseDate") }}</span>
    
            <p> Compagnie d'assurance <b style="color: #ff6141" >*</b> </p>
              <v-select
                v-validate="'required'"
                name="insuranceCompany"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="insuranceCompany"
                :options="insuransces">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('insuranceCompany')">{{ errors.first("insuranceCompany") }}</span>
            
            <p>Type d'assurance<b style="color: #ff6141">*</b></p>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="typeOfInsuranceContract"
              v-model="typeOfInsuranceContract"
              class="w-full"
              
            />
            <span class="text-danger text-sm" v-show="errors.has('typeOfInsuranceContract')">{{ errors.first("typeOfInsuranceContract") }}</span>
            
            
            <p>Numéro d'assurance <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="insurancePolicyNumber"
            v-model="insurancePolicyNumber"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('insurancePolicyNumber')">{{ errors.first("insurancePolicyNumber") }}</span>
            
            
            <p>Date d'expiration <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="expiryDate"
            v-validate="'required'"
            name="expiryDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('expiryDate')">{{ errors.first("expiryDate") }}</span>
            
            <p>Dernière visite technique<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="lastTechnicalVisitDate"
            v-validate="'required'"
            name="lastTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('lastTechnicalVisitDate')">{{ errors.first("lastTechnicalVisitDate") }}</span>
           
            <p>Date de visite technique à venir <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="nextTechnicalVisitDate"
            v-validate="'required'"
            name="nextTechnicalVisitDate"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('nextTechnicalVisitDate')">{{ errors.first("nextTechnicalVisitDate") }}</span>
  
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            
              
            <p>Date de maitenance à venir <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="dateOfTheNextMaintenanceVisit"
            v-validate="'required'"
            name="dateOfTheNextMaintenanceVisit"
            class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('dateOfTheNextMaintenanceVisit')">{{ errors.first("dateOfTheNextMaintenanceVisit") }}</span>
  
             <p> Suivi ivms <b style="color: #ff6141" >*</b> </p>
             <vs-checkbox 
                v-model="ivmsTracker"
                data-vv-validate-on="blur"
                autocomplete
                name="ivmsTracker"
                class="w-full">
            </vs-checkbox>
             
            <span class="text-danger text-sm" v-show="errors.has('ivmsTracker')">{{ errors.first("ivmsTracker") }}</span>
    
            <p>Modèle <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="model"
            v-model="model"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('model')">{{ errors.first("model") }}</span>
         
            <p>Kilomètrage <b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="killometerOnTheDashboard"
            v-model="killometerOnTheDashboard"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('killometerOnTheDashboard')">{{ errors.first("killometerOnTheDashboard") }}</span>
         
            <p>Rapport ivms <b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
                v-model="ivmsReport"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="ivmsReport"
                class="w-full">
            </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('ivmsReport')">{{ errors.first("ivmsReport") }}</span>
            
          <p>Carnet d'entretien <b style="color: #ff6141">*</b></p>
          <vs-checkbox 
                v-model="maintenanceNotebook"
                autocomplete
                name="maintenanceNotebook"
                class="w-full">
            </vs-checkbox>
        
            <span class="text-danger text-sm" v-show="errors.has('maintenanceNotebook')">{{ errors.first("maintenanceNotebook") }}</span>
           
            <p class="pt-4">Image(s)<b style="color: #ff6141" ></b> </p>
          <vs-button color="success" icon-pack="feather" icon="icon-image" @click="Vehicule_Image=true">Choisir</vs-button>
          <vs-popup title="Image(s)" :active.sync="Vehicule_Image" class="items-no-padding">
            <input type="file" class="hidden" ref="updateImgInput1" @change="updateCurrImg1" multiple accept="image/*">
            <input type="file" class="hidden" ref="updateImgInput2" @change="updateCurrImg2" multiple accept="image/*">
            <div class="carousel-example">
              <swiper  :options="swiperOption" style="height: 300px !important;">
                <swiper-slide  v-for="(data, index) in dataImg1" :key="index + '458'">
                  <vs-button
                    icon-pack="feather" icon="icon-trash" size="small" color="danger" @click="Delete_Image({id: data.id,key:index})">Supprimer
                  </vs-button>
                  <br>
                  <img  style="height: 300px !important;" class="responsive" :src= " data.url != null ? $store.state.image_url+data.url : data" alt="banner">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <br>
            <div v-if="this.update_Image === true " class="flex flex-wrap justify-between mb-3">
            <vs-button  icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput2.click()">Ajouter</vs-button>
            <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">Valider</vs-button>
            </div>
            <div v-if="this.update_Image === false " class="flex flex-wrap justify-between mb-3">
              <vs-button icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput1.click()">Ajout Nouvel</vs-button>
              <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">Valider</vs-button>
            </div>
          </vs-popup>
          
            
        </div>
      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Envoyer</vs-button>
     </div>
    </vs-popup>

    <vs-popup
    v-if="activePrompt3" 
      fullscreen 
      class="calendar-event-dialog"
      title="Détails du véhicule"
      :active.sync="activePrompt3">
      <div class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>Nom du véhicule<b style="color: #ff6141">*</b></p>
          <vs-input
            disabled
            name="name"
            v-model="name"
            class="w-full"
          />
  
           <p> Type <b style="color: #ff6141" >*</b> </p>
              <vs-select
                disabled
                v-model="type"
                autocomplete
                name="type"
                class="w-full"
            >
          <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in type_choises" /></vs-select>
                     
            <p>N° de plaque<b style="color: #ff6141">*</b></p>
            <vs-input
              disabled
              name="plateNumber"
              v-model="plateNumber"
              class="w-full"
            />
            
            <p>Carte grise<b style="color: #ff6141">*</b></p>
            <vs-input
              disabled
              name="greyCard"
              v-model="greyCard"
              class="w-full"
            />  
          
            <p>Nombre de ceinture de sécurité 02<b style="color: #ff6141">*</b></p>
            <vs-input-number
            disabled
            :min="0" 
            :max="120"
            name="numberOf02PointsSeatBelt"
            v-model="numberOf02PointsSeatBelt"
            class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('numberOf02PointsSeatBelt')">{{ errors.first("numberOf02PointsSeatBelt") }}</span>
            
            <p>Nombre de ceinture de sécurité 03<b style="color: #ff6141">*</b></p>
            <vs-input-number
            :min="0" 
            :max="120"
            disabled
            name="numberOf03PointsSeatBelt"
            v-model="numberOf03PointsSeatBelt"
            class="w-full"
            />
            <p> Air bague <b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            disabled
            v-validate="'required'"
            v-model="frontAirbag"
            autocomplete
            name="frontAirbag"
            class="w-full">
            </vs-checkbox>
          
            </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

            <p>Date de mise en circulation <b style="color: #ff6141">*</b></p>
            <flat-pickr 
              disabled
              :config="configdateTimePicker" 
              label-placeholder=" Date"
              v-model="firtsReleaseDate"
              name="firtsReleaseDate"
              class="w-full" />
    
            <p> Compagnie d'assurance <b style="color: #ff6141" >*</b> </p>
              <v-select
                disabled
                name="insuranceCompany"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="insuranceCompany"
                :options="insuransces">
              </v-select>
            
            <p>Type d'assurance<b style="color: #ff6141">*</b></p>
            <vs-input
             disabled
              name="typeOfInsuranceContract"
              v-model="typeOfInsuranceContract"
              class="w-full"
              
            />            
            
            <p>Numéro d'assurance <b style="color: #ff6141">*</b></p>
            <vs-input
            disabled
            name="insurancePolicyNumber"
            v-model="insurancePolicyNumber"
            class="w-full"
            />            
            
            <p>Date d'expiration <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="expiryDate"
            name="expiryDate"
            class="w-full" />
            
            <p>Dernière visite technique<b style="color: #ff6141">*</b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="lastTechnicalVisitDate"
            name="lastTechnicalVisitDate"
            class="w-full" />
           
            <p>Date de visite technique à venir <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="nextTechnicalVisitDate"
            name="nextTechnicalVisitDate"
            class="w-full" />  
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
            <p>Date de maitenance à venir <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            disabled
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="dateOfTheNextMaintenanceVisit"
            name="dateOfTheNextMaintenanceVisit"
            class="w-full" />
  
             <p> Suivi ivms <b style="color: #ff6141" >*</b> </p>
             <vs-checkbox 
                disabled
                v-model="ivmsTracker"
                data-vv-validate-on="blur"
                autocomplete
                name="ivmsTracker"
                class="w-full">
            </vs-checkbox>
               
            <p>Modèle <b style="color: #ff6141">*</b></p>
            <vs-input
            disabled
            name="model"
            v-model="model"
            class="w-full"
            />
         
            <p>Kilomètrage <b style="color: #ff6141">*</b></p>
            <vs-input-number
            disabled
            :min="0" 
            name="killometerOnTheDashboard"
            v-model="killometerOnTheDashboard"
            class="w-full"
            />
         
            <p>Rapport ivms <b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
                v-model="ivmsReport"
                disabled
                autocomplete
                name="ivmsReport"
                class="w-full">
            </vs-checkbox>
          <p>Carnet d'entretien <b style="color: #ff6141">*</b></p>
            <vs-checkbox 
                disabled
                v-model="maintenanceNotebook"
                autocomplete
                name="maintenanceNotebook"
                class="w-full">
            </vs-checkbox>
        
        </div>
      </div>
      <vs-button color="primary"  class="float-right mt-4" @click="quit()" >Quitter</vs-button>
     </div>
    </vs-popup>

    <vs-popup classContent="popup-example" title="Ajouter votre commentaire" :active.sync="activePrompt4">
      <vs-textarea
        v-model="comment"
        rows="5"
        class="w-full" >
      </vs-textarea>
      <div class="flex flex-wrap justify-between mt-5 ">
      <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_comfirmation">Annuler</vs-button>
      <vs-button  :disabled="!validate_comment" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="rejete_comfirmation">Valider</vs-button>
      </div>
    </vs-popup>

    <vs-popup classContent="popup-example" title="Détails du rejet" :active.sync="activePrompt5">
      
      <li class="mb-2" v-for="comment in details" :key="comment" >{{ comment.comment }}</li>

    </vs-popup>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import VueImageLightboxCarousel from './SliderComponent'
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr

const dict = {
  custom: {
    name: {
      required: 'Le champ nom  est obligatoire'
    },
    type: {
      required: 'Le champ type est obligatoire'
    }, 
    plateNumber: {
      required: 'Le champ numéro de plaque est obligatoire'
    },
    greyCard: {
      required: 'Le champ catre grise est obligatoire'
    },
    numberOf03PointsSeatBelt: {
      required: 'Le champ nombre de place 03 est obligatoire'
    },
    numberOf02PointsSeatBelt: {
      required: 'Le champ nombre de place 02 est obligatoire'
    },
    frontAirbag: {
      required: 'Le champ air bague est obligatoire'
    },
    firtsReleaseDate: {
      required: 'Le champ première date de sortie est obligatoire'
    },
    insuranceCompany: {
      required: 'Le champ compagnie est obligatoire'
    },
    insurancePolicyNumber: {
      required: 'Le champ numéro d\'assurance est obligatoire'
    },
    typeOfInsuranceContract: {
      required: 'Le champ type de contrat d\'assurance est obligatoire'
    },
    expiryDate: {
      required: 'Le champ date d\'expiration est obligatoire'
    }, 
    lastTechnicalVisitDate: {
      required: 'Le champ dernière visite est obligatoire'
    },
    nextTechnicalVisitDate: {
      required: 'Le champ date de visite technique à venir  est obligatoire'
    },
    ivmsReport: {
      required: 'Le champ rapport ivms est obligatoire'
    },
    dateOfTheNextMaintenanceVisit: {
      required: 'Le champ date de la visite suivante est obligatoire'
    },
    ivmsTracker: {
      required: 'Le champ suivie ivms est obligatoire'
    },
    model: {
      required: 'Le champ modèle est obligatoire'
    },
    killometerOnTheDashboard: {
      required: 'Le champ kilomètrage visite est obligatoire'
    },
    maintenanceNotebook: {
      required: 'Le champ carnet de maintenance est obligatoire'
    }
  }
}
  
// register custom messages
Validator.localize('en', dict)
export default {
  components: {
    VueImageLightboxCarousel,
    swiper,
    swiperSlide,
    flatPickr
  },
  data () {
    return {
      id:'',
      datafiles:[],
      indexfiles:[],
      dataImg: [],
      AddNewdataImg_file: [],
      update_Image:true,
      dataImg1: [],
      dataImg1_delete: [],
      dataImg1_file: [],
      swiperOption: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      configdateTimePicker: {
        enableTime: 'false',
        dateFormat: 'Y-m-d',
        locale: French
      },
      Vehicule_Image:false,
      activePrompt5:false,
      activePrompt4:false,
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      showLightbox: false,
      name:'',
      type:'',
      plateNumber:'',
      greyCard:'',
      numberOf03PointsSeatBelt:'',
      numberOf02PointsSeatBelt:'',
      frontAirbag:0,
      firtsReleaseDate:'',
      insuranceCompany:'',
      typeOfInsuranceContract:'',
      insurancePolicyNumber:'',
      expiryDate:'',
      lastTechnicalVisitDate:'',
      nextTechnicalVisitDate:'',
      dateOfTheNextMaintenanceVisit:'',
      ivmsTracker:0,
      model:'',
      ivmsReport:0,
      killometerOnTheDashboard:'',
      maintenanceNotebook:0,
      proofs:[],
      images: [],
      vehicles: [],
      selected: [],
      type_choises: [
        {
          key: 'truck',
          name: 'Camion'
        },
        {
          key: 'car',
          name: 'Voiture'
        },
        {
          key: 'bus',
          name: 'Bus'
        }
      ],
      air_choises: [
        {
          key: '1',
          name: 'Oui'
        },
        {
          key: '0',
          name: 'Non'
        }
      ],
      carnet_choises: [
        {
          key: '1',
          name: 'Oui'
        },
        {
          key: '0',
          name: 'Non'
        }
      ],
      suivie_choises: [
        {
          key: '1',
          name: 'Oui'
        },
        {
          key: '0',
          name: 'Non'
        }
      ],
      rapport_choises: [
        {
          key: '1',
          name: 'Oui'
        },
        {
          key: '0',
          name: 'Non'
        }
      ],
      itemsPerPage: 20,
      isMounted: false,
      voiture_Object: {
        truck: 'Camion',
        car: 'Voiture',
        bus: 'Bus'

       
      },
      airbag_Object: {
        true: 'Oui',
        false: 'Non'
       
      },
      comment : '',
      vehicule_id : '',
      validationObject: {
        0: 'Rejetée',
        1: 'Encours',
        2: 'Validée'

      },
      details :[]
    }
  },
  computed: {
    validate_comment () {
      if (
        (
          this.comment !== ''  
        )) return true
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.vehicles.length
    }
  },
 
  methods: {
    rapport (tr) {
      this.details = []
      this.activePrompt5 = true
      this.details = tr
    },
    rejete_comfirmation () {
      const input = {
        vehicle : this.vehicule_id,
        comment : this.comment
      }
      //const vehicle = this.vehicule_id
      //const comment = this.comment
      this.$vs.loading()
      this.$http.post('reject-vehicle/', input)
        .then(response => {
          this.getAllVehicles()
          window.getPrendTaCom.success('Rejet validé avec succès.', response)
        })
        .catch(() => {
          window.getPrendTaCom.error({ message: 'Rejet a échoué.' })
        })
      this.activePrompt4 = false
    },
    comfirmation (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation',
        text: 'Confirmer la validation?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          const vehicle = id
          this.$vs.loading()
          this.$http.post('validate-vehicle/', {vehicle})
            .then(response => {
              this.getAllVehicles()
              window.getPrendTaCom.success('Véhicule validé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'Validation échouée.' })
            })
        }
      })
    },

    resetData_comfirmation () {
      this.comment = ''
      this.vehicule_id = ''
      this.activePrompt4 = false
    },
    rejet_Pop (id) {
      this.vehicule_id = id
      this.comment = ''
      this.activePrompt4 = true
    },
    getOrderStatusColor (status) {
      if (status === 0) {
        return 'danger'
      } else if (status === 1) {
        return 'warning'
      } else if (status === 2) {
        return 'success'
      }
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.update_Image = false
      this.activePrompt2 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
     
    },
    ShowData (data) {
      this.intialise()
      this.id = data.id
      this.name = data.name
      this.type = data.type
      this.plateNumber = data.plateNumber
      this.greyCard = data.greyCard
      this.numberOf03PointsSeatBelt = data.numberOf03PointsSeatBelt
      this.numberOf02PointsSeatBelt = data.numberOf02PointsSeatBelt
      this.frontAirbag = data.frontAirbag
      this.firtsReleaseDate = data.firtsReleaseDate
      this.insuranceCompany = data.insuranceCompany.id
      this.typeOfInsuranceContract = data.typeOfInsuranceContract
      this.insurancePolicyNumber = data.insurancePolicyNumber
      this.expiryDate = data.expiryDate
      this.lastTechnicalVisitDate = data.lastTechnicalVisitDate
      this.nextTechnicalVisitDate = data.nextTechnicalVisitDate
      this.dateOfTheNextMaintenanceVisit = data.dateOfTheNextMaintenanceVisit
      this.ivmsTracker = data.ivmsTracker
      this.model = data.model
      this.ivmsReport = data.ivmsReport
      this.killometerOnTheDashboard = data.killometerOnTheDashboard
      this.maintenanceNotebook = data.maintenanceNotebook
      this.activePrompt3 = true
    },
    editData (data) {
      this.intialise()
      this.id = data.id
      this.name = data.name
      this.type = data.type
      this.plateNumber = data.plateNumber
      this.greyCard = data.greyCard
      this.numberOf03PointsSeatBelt = data.numberOf03PointsSeatBelt
      this.numberOf02PointsSeatBelt = data.numberOf02PointsSeatBelt
      this.frontAirbag = data.frontAirbag
      this.firtsReleaseDate = data.firtsReleaseDate
      this.insuranceCompany = data.insuranceCompany.id
      this.typeOfInsuranceContract = data.typeOfInsuranceContract
      this.insurancePolicyNumber = data.insurancePolicyNumber
      this.expiryDate = data.expiryDate
      this.lastTechnicalVisitDate = data.lastTechnicalVisitDate
      this.nextTechnicalVisitDate = data.nextTechnicalVisitDate
      this.dateOfTheNextMaintenanceVisit = data.dateOfTheNextMaintenanceVisit
      this.ivmsTracker = data.ivmsTracker
      this.model = data.model
      this.ivmsReport = data.ivmsReport
      this.killometerOnTheDashboard = data.killometerOnTheDashboard
      this.maintenanceNotebook = data.maintenanceNotebook
      const length = data.vehicleProofs.length
      for (let i = 0; i < length; i++) {
        this.dataImg1.push(data.vehicleProofs[i])
      }
      this.update_Image = true
      this.activePrompt1 = true
    },
    async Delete_Image (data) {

      if (this.id) {
        this.dataImg1_delete.push(data.id)
        this.dataImg1.splice(data.key, 1)
      } else {
        this.dataImg1.splice(data.key, 1)
        this.dataImg1_file.splice(data.key, 1)
        this.AddNewdataImg_file.splice(data.key, 1)
      }
    },

    updateCurrImg1 (input) {
      if (input.target.files && input.target.files[0]) {
        const length = input.srcElement.files.length
        for (let i = 0; i < length; i++) {
          const reader = new FileReader()
          reader.onload = e => {
            // unshift
            this.dataImg1.unshift(e.target.result)
            this.dataImg1_file.unshift(this.$refs.updateImgInput1.files[i])

          }
          reader.readAsDataURL(input.target.files[i])
        }
      }
    },
    updateCurrImg2 (input) {
      if (input.target.files && input.target.files[0]) {
        const length = input.srcElement.files.length
        for (let i = 0; i < length; i++) {
          const reader = new FileReader()
          reader.onload = e => {
            // unshift
            this.dataImg1.unshift(e.target.result)
            this.AddNewdataImg_file.unshift(this.$refs.updateImgInput2.files[i])
          }
          reader.readAsDataURL(input.target.files[i])
        }
      }
    },
    Show_image (tr) {
      this.images = []
      const image_url = 'https://jra.mitigarisk.com/digira/'
      if (tr.vehicleProofs) {
        const length = tr.vehicleProofs.length
        for (let i = 0; i < length; i++) {
          this.images.push(image_url + tr.vehicleProofs[i].url)
        }
      } else {
        this.images = []
      }
    },
    resetColFilters () {
      this.$refs.filterCard.removeRefreshAnimation()
    },
   
    openLightbox () {
      this.showLightbox = true
      this.$refs.lightbox.showImage(0)
    },
    intialise () {
      this.id = ''
      this.name = ''
      this.type = ''
      this.plateNumber = ''
      this.greyCard = ''
      this.numberOf03PointsSeatBelt = ''
      this.numberOf02PointsSeatBelt = ''
      this.frontAirbag = 0
      this.firtsReleaseDate = ''
      this.insuranceCompany = ''
      this.typeOfInsuranceContract = ''
      this.insurancePolicyNumber = ''
      this.expiryDate = ''
      this.lastTechnicalVisitDate = ''
      this.nextTechnicalVisitDate = ''
      this.dateOfTheNextMaintenanceVisit = ''
      this.ivmsTracker = 0
      this.model = ''
      this.ivmsReport = 0
      this.killometerOnTheDashboard = ''
      this.maintenanceNotebook = 0
      this.proofs = []
      this.dataImg1 = []
      this.dataImg1_file = []
      this.AddNewdataImg_file = []
      this.dataImg1_delete = []
    },
    
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`vehicles/${id}/`)
            .then(response => {
              this.getAllVehicles()
              window.getPrendTaCom.success('Le véhicule est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
  
    async submitData () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const formdata = new FormData()
          this.$vs.loading()
          formdata.append('name', this.name)
          formdata.append('type', this.type)
          formdata.append('plateNumber', this.plateNumber)
          formdata.append('greyCard', this.greyCard)
          formdata.append('numberOf03PointsSeatBelt', this.numberOf03PointsSeatBelt)
          formdata.append('numberOf02PointsSeatBelt', this.numberOf02PointsSeatBelt)
          formdata.append('firtsReleaseDate', this.firtsReleaseDate)
          formdata.append('frontAirbag', this.frontAirbag)
          formdata.append('insuranceCompany', this.insuranceCompany)
          formdata.append('typeOfInsuranceContract', this.typeOfInsuranceContract)
          formdata.append('insurancePolicyNumber', this.insurancePolicyNumber)
          formdata.append('expiryDate', this.expiryDate)
          formdata.append('lastTechnicalVisitDate', this.lastTechnicalVisitDate)
          formdata.append('nextTechnicalVisitDate', this.nextTechnicalVisitDate)
          formdata.append('dateOfTheNextMaintenanceVisit', this.dateOfTheNextMaintenanceVisit)
          formdata.append('ivmsTracker', this.ivmsTracker)
          formdata.append('model', this.model)
          formdata.append('ivmsReport', this.ivmsReport)
          formdata.append('killometerOnTheDashboard', this.killometerOnTheDashboard)
          formdata.append('maintenanceNotebook', this.maintenanceNotebook)
          //Ajout d'image
          if (this.dataImg1_file.length) {
            const length = this.dataImg1_file.length
            for (let i = 0; i < length; i++) {
              formdata.append('proofs', this.dataImg1_file[i],  this.dataImg1_file[i].name)
            }
          }
          //Ajout de nouvelle d'image
          let i = 0
          if (this.AddNewdataImg_file.length) {
            if (this.id) {
              const length = this.AddNewdataImg_file.length
              for (i = 0; i < length; i++) {
                formdata.append('proofs_add', this.AddNewdataImg_file[i], this.AddNewdataImg_file[i].name)
              }
            } else {
              formdata.append('proofs_add', '')
            }
          }
          //suppression d'image

          if (this.dataImg1_delete.length) {
            //const length = this.dataImg1_delete.length
            //for (i = 0; i < length; i++) {
            formdata.append('proofs_delete', this.dataImg1_delete)
            //}
          } else {
            formdata.append('proofs_delete', '')
          }

          let url = 'vehicles/'
          let methods = 'post'
          const message = {
            error: 'Votre enregistrement à échouer.',
            success: 'le véhicule est enrégistré avec succès.'
          }
          if (this.id) {
            url += `${this.id}/`
            methods = 'put'
            message.success = 'Le véhicule est modifié avec succès.'

          }

          this.$http[methods](url, formdata)
            .then((response) => {
              window.Vehicles.getAllVehicles()
              this.dataImg1 = []
              window.getPrendTaCom.success(message.success, response)
              this.dataImg1 = []
              this.dataImg1_delete = []
              this.activePrompt1 = false
              this.activePrompt2 = false
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                let libelle = ''
                if (item === 'name') {
                  libelle = 'Nom'
                } else if (item === 'type') {
                  libelle = 'Type'
                } else if (item === 'plateNumber') {
                  libelle = 'Numéro de plaque'
                } else if (item === 'greyCard') {
                  libelle = 'Catre grise'
                } else if (item === 'numberOf03PointsSeatBelt') {
                  libelle = 'Nombre de ceinture de sécurité de 3 place'
                } else if (item === 'numberOf02PointsSeatBelt') {
                  libelle = 'Nombre de ceinture de sécurité de 2 place'
                } else if (item === 'frontAirbag') {
                  libelle = 'Air bague'
                } else if (item === 'firtsReleaseDate') {
                  libelle = 'Première date de sortie'
                } else if (item === 'insuranceCompany') {
                  libelle = 'Compagny d\'assurance'
                } else if (item === 'typeOfInsuranceContract') {
                  libelle = 'Type d\'assurance'
                } else if (item === 'insurancePolicyNumber') {
                  libelle = 'Numéro d\'assurance'
                } else if (item === 'expiryDate') {
                  libelle = 'Date d\'expiration'
                } else if (item === 'lastTechnicalVisitDate') {
                  libelle = 'Dernière date visite technique'
                } else if (item === 'nextTechnicalVisitDate') {
                  libelle = 'Prochaine date visite technique'
                } else if (item === 'ivmsTracker') {
                  libelle = 'Suivi ivms'
                } else if (item === 'model') {
                  libelle = 'Modèle'
                } else if (item === 'ivmsReport') {
                  libelle = 'Rapport ivms'
                } else if (item === 'killometerOnTheDashboard') {
                  libelle = 'Kilomètrage'
                } else if (item === 'maintenanceNotebook') {
                  libelle = 'Carnet de maintenance'
                } else if (item === 'proofs') {
                  libelle = 'Image'
                }
                for (let j = 0; j < error.response.data[item].length; j++) {
                  window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
                }
              }
              window.getPrendTaCom.error(message.error)
            })
        }
      })
      
    },

    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
   
    getAllVehicles () {
      this.$vs.loading()
      this.$http.get('activated-vehicle/')
        .then((response) => {
          this.vehicles = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllInsuransces () {
      this.$vs.loading()
      this.$http.get('insuranceCompanies/')
        .then((response) => {
          this.insuransces = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.Vehicles = this
    this.getAllVehicles()
    this.getAllInsuransces()

  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
<style scoped>

</style>
